export default class UtilService {
  constructor(source) {
    this.source = source;
  }

  Loading() {}

  ConvertBase64(file, callback, callbackErro) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
    reader.onerror = function (error) {
      callbackErro(error);
    };
  }

  ExtrairNumeros(str) {
    if (str && str.length > 0) {
      let lNumbers = str.match(/\d+/g);
      let retorno = [];
      for (let counter = 0; counter < lNumbers.length; counter++) {
        retorno.push(lNumbers[counter]);
      }
      return this.ReplaceAll(retorno.toString(), ",", "");
    } else {
      return null;
    }
  }

  OnlyNumbers(str) {
    var n = str.match(/\d+/g);
    if (!n) n = 0;
    else n = n[0];
    return n;
  }

  ReplaceAll(str, oldStr, newStr) {
    while (str.indexOf(oldStr) > -1) {
      str = str.replace(oldStr, newStr);
    }
    return str;
  }

  RandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  IsCpf(cpf) {
    if (cpf) {
      cpf = this.ExtrairNumeros(cpf);
      if (cpf.length !== 11) {
        return false;
      }
      while (cpf.length < 11) {
        cpf = "0" + cpf;
      }
      let multiplicador1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];
      let multiplicador2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
      let tempCpf = cpf.substring(0, 9);
      let soma = 0;
      for (let i = 0; i < 9; i++) {
        soma += parseInt(tempCpf[i].toString()) * multiplicador1[i];
      }
      let resto = soma % 11;
      if (resto < 2) {
        resto = 0;
      } else {
        resto = 11 - resto;
      }
      let digito = resto.toString();
      tempCpf = tempCpf + digito;
      soma = 0;
      for (let i = 0; i < 10; i++) {
        soma += parseInt(tempCpf[i].toString()) * multiplicador2[i];
      }
      resto = soma % 11;
      if (resto < 2) {
        resto = 0;
      } else {
        resto = 11 - resto;
      }
      digito = digito + resto;
      return cpf.endsWith(digito);
    } else return false;
  }

  FormatCpf(value) {
    if (value) {
      value = value.replace(/\D/g, "");
      value = value.length > 11 ? value.substring(0, 11) : value;
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    }
    return value;
  }

  FormatarCpfCnpj(v) {
    if (!v) return "";
    // Remove tudo o que não é dígito
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      // CPF
      return v
        .replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
        .replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Coloca um hífen entre o terceiro e o quarto dígitos
    }
    // CNPJ
    return v
      .replace(/^(\d{2})(\d)/, "$1.$2") // Coloca ponto entre o segundo e o terceiro dígitos
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") // Coloca ponto entre o quinto e o sexto dígitos
      .replace(/\.(\d{3})(\d)/, ".$1/$2") // Coloca uma barra entre o oitavo e o nono dígitos
      .replace(/(\d{4})(\d)/, "$1-$2"); // Coloca um hífen depois do bloco de quatro dígitos
  }

  FormatDecimal(amount, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  NumberToReal(numero, removerDecimal, noSymbol) {
    if (numero >= 0 && numero != null) {
      let isNegative = numero < 0;
      if (isNegative) numero = numero * -1;

      numero = numero.toFixed(2).split(".");
      let symbol = !noSymbol ? "R$ " : "";
      numero[0] = symbol + numero[0].split(/(?=(?:...)*$)/).join(".");
      let retorno = numero.join(",");
      if (removerDecimal) {
        retorno = retorno.split(",")[0];
      }

      if (isNegative) retorno = "- " + retorno;
      return retorno;
    }
  }

  FormatMilhar(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  }

  CalcularAlinhamentoElemento(element, heightTarget, widthTarget) {
    if (element) {
      let height = element.offsetHeight;
      let width = element.offsetWidth;
      return {
        alinhamentoVertical: height / 2 - heightTarget / 2,
        alinhamentoHorizontal: width / 2 - widthTarget / 2,
      };
    }
  }

  getUnique(arr, comp) {
    if (comp) {
      const unique = arr
        .map((e) => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e])
        .map((e) => arr[e]);
      return unique;
    } else {
      let distinct = (value, index, self) => {
        return self.indexOf(value) === index;
      };

      return arr.filter(distinct);
    }
  }

  OrderBy(lista, propName) {
    if (lista)
      return lista.sort((x, y) => {
        if (propName) {
          return x[propName] < y[propName] ? -1 : 1;
        } else {
          return x < y ? -1 : 1;
        }
      });
  }

  retornaApenasNumerosEPontos(str) {
    return str ? str.replace(/[^0-9.]/g, "") : "";
  }

  retornaApenasNumeros(str) {
    return str ? str.replace(/[^0-9]/g, "") : "";
  }

  OrderByDesc(lista, propName) {
    return lista.sort((x, y) => {
      if (propName) {
        return x[propName] < y[propName] ? 1 : -1;
      } else {
        return x < y ? 1 : -1;
      }
    });
  }

  EmailValido(email) {
    let regEmail = RegExp(
      '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      "i"
    );
    return regEmail.test(email);
  }

  Clone(obj) {
    let clone = JSON.stringify(obj);
    return JSON.parse(clone);
  }

  CopyToClipboard(str) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  IsCompact(val) {
    let width = val > 0 ? val : 991;
    return window.innerWidth <= width;
  }

  StringToLocaleDateString(str) {
    const date = new Date(str);
    return date.toLocaleDateString();
  }

  GetParent(source, parentName) {
    let target = source;
    do {
      target = target.$parent;
    } while (target && target.$options.name !== parentName);
    return target;
  }

  ScrollTo(idTarget) {
    let top = !idTarget
      ? 0
      : document.getElementById(idTarget).getBoundingClientRect().top;
    document.getElementById("div-content").scrollTo({
      top: top,
      behavior: "smooth",
    });
  }

  GetHeaderPage(source) {
    let app = this.GetParent(source, "App");
    return app.$refs.headerPage;
  }

  SetDropdownHeaderPage(source, itens) {
    let headerPage = this.GetHeaderPage(source);
    headerPage.dropdown.parent = source.$route.name;
    headerPage.dropdown.itens = itens;
    headerPage.fnSave.fn = null;
  }

  SetMsgLoadingHeaderPage(source, msg) {
    let headerPage = this.GetHeaderPage(source);
    headerPage.dropdown.msgLoading = msg;
  }

  SetFnHeaderSave(source, fn) {
    let headerPage = this.GetHeaderPage(source);
    if (headerPage) {
      headerPage.fnSave.parent = source.$route.name;
      headerPage.fnSave.fn = fn;
    }
  }

  RemoveFnHeaderSave(source) {
    let headerPage = this.GetHeaderPage(source);
    if (headerPage) {
      headerPage.fnSave.parent = source.$route.name;
      headerPage.fnSave.fn = null;
    }
  }

  IsAdmin(source) {
    return this.GetParent(source, "App").isAdmin;
  }

  UrlCombine(baseURL, relativeURL) {
    let retorno = "";
    // Se o URL relativo já é um URL absoluto, retorná-lo sem alterações
    if (
      relativeURL.startsWith("http://") ||
      relativeURL.startsWith("https://")
    ) {
      retorno = relativeURL;
    }

    // Adicionar uma barra no final do URL base, se necessário
    if (!baseURL.endsWith("/")) {
      baseURL += "/";
    }

    // Verificar se o URL relativo começa com uma barra
    if (relativeURL.startsWith("/")) {
      // Remover a barra extra do final do URL base, se necessário
      if (baseURL.endsWith("/")) {
        baseURL = baseURL.slice(0, -1);
      }
      // Combinar o URL base e o URL relativo e retorná-lo
      retorno = baseURL + relativeURL;
    } else {
      // Adicionar uma barra ao final do URL base, se necessário
      if (!baseURL.endsWith("/")) {
        baseURL += "/";
      }
      // Combinar o URL base e o URL relativo e retorná-lo
      retorno = baseURL + relativeURL;
    }
    return retorno;
  }

  Debounce(fn, delay) {
    let timeoutID;
    return function (...args) {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      timeoutID = setTimeout(() => fn.apply(this, args), delay);
    };
  }

  GuidEmpty() {
    return "00000000-0000-0000-0000-000000000000";
  }
}
