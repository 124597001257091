import HttpService from "@/services/HttpService";
import axios from 'axios'

export default class RepositoryService {
    constructor() {
        this.client = new HttpService(this, process.env.VUE_APP_URL_REPOSITORIO, true)
    }

    Post(file, folder, callback, callbackErro) {
        let formData = new FormData()
        if (file)
            formData.append('file', file)
        if(folder)
            formData.append('folder', folder)
        this.client.Post('file', formData, callback, callbackErro)
    }

    GravarImagem(file, folder, callback, callbackErro) {
        
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('file', file);

        axios.post(process.env.VUE_APP_URL_API + 'pessoa/GravarImagem?idUsuario=' + folder, formData, config)
            .then(response => {
                callback(response.data)
            })
            .catch(erroPost => {
                callbackErro(erroPost)
            })

    }

    GetImageUser(usuario, callback) {
        var result =  process.env.VUE_APP_URL_REPOSITORIO + 'repository/usuario/' + usuario + '/' + usuario + '.webp';

        return callback(result);

        // this.client.Get('repository/geturlimagemusuario?usuario=' + usuario, callback, callbackErro)
    }

    GetBlob(folder, callback, callbackErro) {
        this.client.Get('FileBlob?path=' + folder, callback, callbackErro)
    }

    List(folder, callback, callbackErro) {
        this.client.Get('file?folderPath=' + folder, callback, callbackErro)
    }

    Delete(file, folder, callback, callbackErro) {
        this.client.Delete(`file?file=${file}&folder=${folder}`, callback, callbackErro)
    }

    ListFilesBlob(folder, callback, callbackErro) {
        this.client.Get(`FileBlob?path=${folder}`, callback, callbackErro)
    }

    DeleteFileBlob(path, callback, callbackErro) {
        this.client.Delete(`FileBlob?path=${path}`, callback, callbackErro)
    }

    DeleteClientImage(client, callback, callbackErro) {

        var req = {
            Tipo: 9,
            Folder: client,
            FileName: client
        }
        console.log(req);
        this.client.Post(`repository/ApagarImagem`, req, callback, callbackErro);
    }
}