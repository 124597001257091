<template>
  <div>
    <el-dropdown class="icon-top icon-top-a dropdown-user" trigger="click" @command="AcaoUsuario" placement="bottom">
      <span class="el-dropdown-link">
        <span class="show-starting-1200 hideOnlyMobile" v-if="UsuarioLogado"
          style="display: flex; align-items: center;">
          <div>
            <div v-if="urlFile" :md="4" :xs="24" class="user-info-2">
              <img :src="urlFile" @error="handleImageError" class="avatar-2" alt="User Image" />
            </div>
            <div v-else class="user-info" :style="`--avatar-color: ${avatarColor}`">
              <span class="avatar">{{ getAvatarInitials(UsuarioLogado.Nome) }}</span>
            </div>
          </div>
          <div style="text-align: left;">
            <div class="spn-nome-usuario" v-if="UsuarioLogado && UsuarioLogado.Nome">
              {{ UsuarioLogado.Nome.split(" ")[0] }}
              <span class="el-dropdown-icon"><i class="el-icon-arrow-down el-icon--right"></i></span>
            </div>
            <div v-if="logadoComo && UsuarioLogado.IdPermissao === 1" class="nome-logado-como">
              {{ logadoComo.Nome }}
            </div>
            <span v-if="ParceiroAtual && !logadoComo" class="nome-logado-como">{{ ParceiroAtual.Nome }}</span>
          </div>
        </span>
        <span class="el-dropdown-link hide-starting-992 icon-top icon-top-a">
          <i class="el-icon-user-solid"></i>
        </span>
      </span>
      <el-dropdown-menu slot="dropdown" v-if="UsuarioLogado">
        <el-dropdown-item v-if="logadoComo && UsuarioLogado.IdPermissao === 1" disabled>
          <span v-if="logadoComo && UsuarioLogado.IdPermissao === 1"
            class="hide-starting-1200 spn-logado-como text-uppercase">
            {{ logadoComo.Nome }}
          </span>
          <span class="spn-nome-usuario" v-else-if="ParceiroAtual"><em>{{ UsuarioLogado.Nome }} </em></span>
        </el-dropdown-item>
        <el-dropdown-item class="logado-na" command="goToUser">
          <el-row>
            <el-col :span="7">
              <div v-if="urlFile" :md="4" :xs="24" class="user-info-3">
                <img :src="urlFile" class="avatar-3" alt="User Image" />
              </div>
              <div v-else class="user-info-3 dropdown-user dropdown-user-2" :style="`--avatar-color: ${avatarColor}`">
                <span class="avatar-3">{{ getAvatarInitials(UsuarioLogado.Nome) }}</span>
              </div>
            </el-col>
            <el-col :span="17">
              <span size="small" v-if="UsuarioLogado && UsuarioLogado.Nome">Olá, {{ UsuarioLogado.Nome.split(" ")[0]
                }}!</span><br />
              <b class="b-nome-empresa" v-if="ParceiroAtual">{{ ParceiroAtual.Nome }}</b>
            </el-col>
          </el-row>
        </el-dropdown-item>
        <el-dropdown-item v-if="temPermissao" divided command="verPlano" class="hideOnlyMobile hideOnIpad">
          <i class="el-icon-user"></i> Meu plano
        </el-dropdown-item>
        <el-dropdown-item command="trocarConta" v-if="UsuarioLogado && (UsuarioLogado.IdPermissao === 1 || PossuiRede)">
          <i class="el-icon-guide"></i> Acessar outra conta
        </el-dropdown-item>
        <el-dropdown-item command="convidarAmigos" @click.native="convidarAmigos">
          <i class="el-icon-share"></i> Convidar amigos
        </el-dropdown-item>
        <el-dropdown-item command="logout" divided>
          <i class="el-icon-switch-button"></i> Sair
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <modal-logar-como ref="mdLogarComo" />
  </div>
</template>


<script>
import LoginService from "@/services/LoginService";
import ParceiroService from "@/services/ParceiroService";
import ModalLogarComo from "@/components/layout/CabecalhoLayout/ModalLogarComo";
import RepositoryService from "@/services/RepositoryService";

export default {
  name: "DropdownUsuarioAtual",
  components: { ModalLogarComo },
  props: ["UsuarioLogado", "UsuarioId"],
  data() {
    return {
      urlFile: '',
      logadoComo: null,
      avatarColors: [
        "#ff7f50",
        "#ff6b6b",
        "#00bfff",
        "#1e90ff",
        "#6a5acd",
        "#ba55d3",
        "#32cd32",
        "#f0e68c",
        "#ff69b4",
        "#ff1493",
        "#fa8072",
        "#7fffd4",
        "#8a2be2",
        "#ff4500",
        "#daa520",
      ],
      permissao: [1, 3],
      temPermissao: true,
    };
  },
  created() {
    this.parceiroService = new ParceiroService(this);
    this.repoService = new RepositoryService();
    if (this.UsuarioLogado && this.UsuarioLogado.Id) {
      this.repoService.GetImageUser(this.UsuarioLogado.Id, this.CallbackGetImage);
      this.loginService = new LoginService(this);
      this.VerificarLogadoComo();
      this.GetPermissao();
    } else {
      console.error("UsuarioLogado or UsuarioLogado.Id is undefined");
    }
  },

  methods: {
    goToUser() {
      if (this.UsuarioLogado && this.UsuarioLogado.Id) {
        console.log("Navigating to:", `/usuario/${this.UsuarioLogado.Id}`);
        this.$router.push(`/VerUsuario/${this.UsuarioLogado.Id}`);
      }
    },
    AcaoUsuario(command) {
      if (command === "logout") {
        localStorage.clear();
        this.$router.push("/login");
      } else if (command === "trocarConta") {
        this.$refs.mdLogarComo.Show();
      } else if (command === "verPlano") {
        this.$router.push("/MeuPlano");
      } else if (command === "goToUser") {
        this.goToUser();
      }
    },
    CallbackGetImage(res) {
      this.urlFile = '';
      if (!res?.length) return;
      this.urlFile = `${res}?v=${Date.now()}`;
    },
    handleImageError() {
      this.urlFile = ''; // Limpa a URL em caso de erro
    },
    getAvatarInitials(nome) {
      // Verifica se o nome é nulo, indefinido ou não é uma string
      if (!nome || typeof nome !== 'string') {
        return 'Oi!'; // Retorna um valor padrão
      }

      const partesNome = nome.split(" ");
      let primeiraLetra = partesNome[0].charAt(0).toUpperCase();
      let segundaLetra =
        partesNome.length > 1
          ? partesNome[partesNome.length - 1].charAt(0).toUpperCase()
          : partesNome[0].charAt(1).toUpperCase();

      return primeiraLetra + segundaLetra;
    },
    convidarAmigos() {
      window.open('https://moblix.com.br/seja-um-parceiro-moblix/', '_blank');
    },
    getAvatarColorIndex(nome) {
      let sum = 0;
      if (nome) for (let i = 0; i < nome.length; i++) sum += nome.charCodeAt(i);
      return sum % this.avatarColors.length;
    },
    VerificarLogadoComo() {
      if (this.UsuarioLogado.IdPermissao === 1 || this.PossuiRede) {
        this.loginService.LogadoComo(this.CallbackLogadoComo);
      } else {
        this.parceiroService._GetAppMain().logadoComoVerificado = true;
      }
    },
    CallbackLogadoComo(response) {
      if (response.HasResult) {
        this.logadoComo = response.Data[0];
      } else {
        this.logadoComo = null;
      }
      this.parceiroService.SetLogadoComo(this.logadoComo);
      this.parceiroService._GetAppMain().logadoComoVerificado = true;
    },
    GetPermissao() {
      this.temPermissao = this.permissao.includes(
        this.UsuarioLogado.IdPermissao
      );
    },
    CallbackError(erro) {
      this.$message.error(erro.Erro.Message);
    }
  },
  computed: {
    PossuiRede() {
      return (
        this.UsuarioLogado &&
        this.UsuarioLogado.Rede &&
        this.UsuarioLogado.Rede.length > 0
      );
    },
    ParceiroAtual() {
      if (this.logadoComo) return this.logadoComo;
      else if (this.UsuarioLogado) return this.UsuarioLogado.Parceiro;
      else return null;
    },
    avatarColor() {
      if (this.UsuarioLogado) {
        const index = this.getAvatarColorIndex(this.UsuarioLogado.Nome);
        return this.avatarColors[index];
      }
      return this.avatarColors[0];
    },
  },
};
</script>



<style scoped>
.dropdown-user {
  color: var(--gray);
  font-weight: bold;
  margin-right: 55px;
}

.dropdown-user-2 {
  margin-right: 0px !important;
}

.b-nome-empresa {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.user-info {
  padding: 10px 5px 10px 10px;
}

.user-info-2 {
  padding: 10px 5px 10px 10px;
}

.dropdown-user .user-info {
  display: inline-block;
}

.dropdown-user .avatar {
  display: inline-block;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  border-radius: 20%;
  margin-right: 5px;
  background-color: var(--avatar-color);
  color: #fff;
  font-size: 12px;
}

.avatar-2 {
  display: inline-block;
  height: 32px;
  width: 32px;
  object-fit: cover;
  text-align: center;
  line-height: 32px;
  border-radius: 20%;
  margin-right: 5px;
  background-color: var(--avatar-color);
  color: #fff;
  font-size: 12px;
}

.avatar-3 {
  display: inline-block;
  height: 44px;
  width: 44px;
  -o-object-fit: cover;
  object-fit: cover;
  text-align: center;
  line-height: 44px;
  border-radius: 20%;
  margin-top: 5px;
  background-color: var(--avatar-color);
  color: #fff;
}

.el-icon-user-solid {
  font-size: 20px !important;
}

.logado-na {
  line-height: 25px !important;
  font-size: 14px;
  min-width: 240px;
}

.nome-logado-como {
  font-size: 12px;
  font-weight: 500 !important;
}

.spn-nome-usuario {
  text-align: left;
}

.spn-nome-usuario,
.nome-logado-como {
  margin-bottom: -2px;
}

@media only screen and (max-width: 600px) {
  .dropdown-user {
    margin-right: 10px;
    padding: 10px;
    top: 4px;
  }
}
</style>
