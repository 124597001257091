import PostService from './PostService'
import LocalStorageService from './LocalStorageService'
import HttpService from "@/services/HttpService";
export default class LoginService {
    constructor(source) {
        this.postService = new PostService(source)
        this.ls = new LocalStorageService()
        this.httpService = new HttpService(this.source, process.env.VUE_APP_URL_AUTHENTICATION, false)
    }

    Login(user, pass, callback, callbackErro) {
        let ref = this
        let rq = { Username: user, Password: pass }
        this.httpService.Post('Token', rq, (res) => {
            ref.SalvarToken(res.access_token, res.expires_in / 60)
            callback(res);
        }, callbackErro, null, 'application/x-www-form-urlencoded')
    }

    ValidarHash(hash, callback, callbackErro) {
        let url = '/login/ValidarHash?hash=' + hash
        this.postService.Post(url, null, callback, callbackErro)
    }

    LembrarSenha(user, callback, callbackErro) {
        let url = '/login/LembrarSenha?usuario=' + user + '&portal=false'
        this.postService.Post(url, null, callback, callbackErro, true)
    }

    MudarSenha(rq, callback, callbackErro) {
        this.postService.Post('/Login/AlterarSenha', rq, callback, callbackErro, true)
    }

    SalvarToken(token, minutes) {
        minutes = parseInt(minutes)
        this.ls.Set(process.env.VUE_APP_KEY_TOKEN_CACHE, token, minutes)
    }

    PegarToken() {
        return this.ls.Get(process.env.VUE_APP_KEY_TOKEN_CACHE)
    }

    LogarComo(idAgencia, callback) {
        let rq = { TokenBearer: this.PegarToken(), IdAgenciaLogarComo: idAgencia }
        this.httpService.Post('Token', rq, callback,null, null, 'application/x-www-form-urlencoded')
    }

    LogadoComo(callback) {
        this.postService.Post('login/LogadoComo', null, callback, null, true)
    }

    ParceiroAtual(callback) {
        this.postService.Post('login/ParceiroAtual', null, callback, null, true)
    }
}
